//jsxhook
import {
  Colors,
  Fonts,
  FontWeight,
  LineHeights,
  Paragraph,
  StandardXAxisPadding
} from "@sixty-six-north/ui-system"
import { usePreferredLanguage } from "i18n/StoreHooks"
import { NextPage } from "next"
import Head from "next/head"
import { PrismicDocumentLink } from "prismic/slices/PrismicLink"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import { GlobalProps } from "../next/GlobalProps"
import { useGoogleAnalytics } from "../tagmanager/AnalyticsHooks"
import { WrongTurn } from "./WrongTurn"

export interface ErrorPageProps {
  statusCode: number
}

export const ErrorPage: NextPage<ErrorPageProps & GlobalProps> = () => {
  const { t } = useTranslation("category")
  const language = usePreferredLanguage()

  const googleAnalytics = useGoogleAnalytics()
  useEffect(() => {
    googleAnalytics.clear()
  }, [])

  return (
    <>
      <Head>
        <title>{[t("Error"), "|", t("66ºNorth")].join(" ")}</title>
      </Head>
      <WrongTurn
        title={t("error:pageNotFound", {
          defaultValue: "Sorry the page could not be found"
        })}
        message={t("layout:egKemAlvegAfFjollum", {
          defaultValue:
            'Ég kem alveg af fjöllum — "I come completely from mountains"{" "}'
        })}
      />
      {
        //TESTING OUT if the 404 drives any sign up traffic or conversions
        language !== "is" && (
          <Flex
            sx={{
              bg: `${Colors.blue}.2`,
              px: StandardXAxisPadding,
              color: `${Colors.grey}.2`,
              py: 72,
              flexDirection: "column"
            }}
          >
            <h4
              sx={{
                fontFamily: Fonts.text,
                fontWeight: FontWeight.textRegular,
                lineHeight: LineHeights.editorial,
                fontSize: ["scale1", "scale1", "scale2", "scale2"],
                color: `${Colors.white}.0`
              }}
            >
              Win a trip to Iceland
            </h4>
            <Paragraph
              sx={{
                maxWidth: "48ch",
                mt: 8
              }}
            >
              <PrismicDocumentLink
                documentId={"YJpRbREAACMA84JU"}
                sx={{ color: `${Colors.white}.0` }}
              >
                Sign up to the 66ºNorth club
              </PrismicDocumentLink>{" "}
              to enter our 3x a year drawings for free trips, gear and guided
              tours and 10% off your first order.
            </Paragraph>
          </Flex>
        )
      }
    </>
  )
}

export default ErrorPage
